<template>
  <el-dialog v-dialog-drag width="70%" :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="培训主题" prop="title">
            <el-input v-model="dataForm.title" placeholder="培训主题"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="培训日期" prop="trainingDate">
            <el-date-picker v-model="dataForm.trainingDate" type="date" placeholder="培训日期" value-format="yyyy-MM-dd" style="width: 100%;" :clearable="false"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="主办机构" prop="organizer">
            <el-input v-model="dataForm.organizer" placeholder="主办机构"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="培训地点" prop="address">
            <el-input v-model="dataForm.address" placeholder="培训地点"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="应到人数" prop="supposedNum">
            <el-input-number v-model="dataForm.supposedNum" data-unit="人" placeholder="应到人数" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="实到人数" prop="attendanceNum">
            <el-input-number v-model="dataForm.attendanceNum" data-unit="人" placeholder="实到人数" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="学习内容" prop="contents">
            <el-input type="textarea" v-model="dataForm.contents" :autosize="{ minRows: 3 }"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注说明" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 3 }"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        title: '',
        trainingDate: '',
        organizer: '',
        address: '',
        supposedNum: '',
        attendanceNum: '',
        contents: '',
        remark: ''
      }
    }
  },
  computed: {
    dataRule() {
      return {
        title: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        trainingDate: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        organizer: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        address: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/opm/safetyTraining/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/opm/safetyTraining/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>