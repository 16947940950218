<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-sys__log-login">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.creatorName" :placeholder="$t('logLogin.creatorName')" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="export" @click="exportHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" style="width: 100%;" :row-class-name="tableRowClassName">
        <el-table-column prop="creatorName" label="登录用户" align="center" width="200" sortable="custom"></el-table-column>
        <el-table-column prop="operation" label="操作类型" align="center" width="120" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.operation === 0 ? $t('logLogin.operation0') : $t('logLogin.operation1') }}
          </template>
        </el-table-column>
        <el-table-column prop="ip" label="IP地址" align="center" width="180" sortable="custom"></el-table-column>
        <el-table-column prop="createDate" label="操作时间" sortable="custom" align="center" width="180"></el-table-column>
        <el-table-column prop="userAgent" label="用户设备" show-overflow-tooltip sortable="custom"></el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/sys/log/login/page',
        getDataListIsPage: true,
        exportURL: '/sys/log/login/export'
      },
      dataForm: {
        creatorName: ''
      }
    }
  },
  methods: {
    tableRowClassName ({ row, rowIndex }) {
      if (row.operation === 0) {
        return 'row-login'
      } else {
        return 'row-logout'
      }
    }
  }
}
</script>
<style>
  .el-table .row-login {
    background: white;
  }
  .el-table .row-logout {
    background: #FFFFE0;
  }
</style>
