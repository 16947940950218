<template>
  <div>
    <!-- 第1行 -->
    <el-row :gutter="5" style="margin-top: 5px;">
      <el-col :span="24">
        <el-card>
          <div slot="header" class="header-default">
            <i class="el-icon-picture-outline"></i><span> 质量问题统计</span>
          </div>
          <el-row :gutter="12">
            <el-col :span="24">
              <el-card shadow="hover">
                <div>
                  <project-select v-model="qutyCheckChart.prjId" placeholder="项目选择" :defaultSelect="true" style="width: 200px;" @change="qutyCheckChartHandle"></project-select>
                  <el-select style="width: 100px" v-model="qutyCheckChart.year" filterable @change="qutyCheckChartHandle">
                    <el-option v-for="y in qutyCheckChart.years" :label="y" :key="y" :value="y"></el-option>
                  </el-select>
                </div>
                <el-row :gutter="12">
                  <el-col :span="18">
                    <div v-echart-resize id="qutyCheckColumnChart" :style="{width: '100%', height: '260px'}"/>
                  </el-col>
                  <el-col :span="6">
                    <div v-echart-resize id="qutyCheckPieChart" :style="{width: '100%', height: '260px'}"/>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <!-- 第2行 -->
    <el-row :gutter="5" style="margin-top: 5px;">
      <el-col :span="24">
        <el-card>
          <div slot="header" class="header-default">
            <i class="el-icon-picture-outline"></i><span> 安全问题统计</span>
          </div>
          <el-row :gutter="12">
            <el-col :span="24">
              <el-card shadow="hover">
                <div>
                  <project-select v-model="safetyCheckChart.prjId" placeholder="项目选择" :defaultSelect="true" style="width: 200px;" @change="safetyCheckChartHandle"></project-select>
                  <el-select style="width: 100px" v-model="safetyCheckChart.year" filterable @change="safetyCheckChartHandle">
                    <el-option v-for="y in safetyCheckChart.years" :label="y" :key="y" :value="y"></el-option>
                  </el-select>
                </div>
                <el-row :gutter="12">
                  <el-col :span="18">
                    <div v-echart-resize id="safetyCheckColumnChart" :style="{width: '100%', height: '260px'}"/>
                  </el-col>
                  <el-col :span="6">
                    <div v-echart-resize id="safetyCheckPieChart" :style="{width: '100%', height: '260px'}"/>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
// 质量、安全统计分析
import {addDynamicRoute} from '@/router'
import { HashMap } from '@/utils'
export default {
  data () {
    return {
      // 质量检查问题统计
      qutyCheckChart: {
        prjId: '',
        year: new Date().getFullYear(),
        years: this.makeYearOptions(),
        instance1: null,
        instance2: null,
      },
      // 安全检查问题统计
      safetyCheckChart: {
        prjId: '',
        year: new Date().getFullYear(),
        years: this.makeYearOptions(),
        instance1: null,
        instance2: null,
      },
      chartLoadingText:{// 图表loading效果
        text: '统计中，请稍候...',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(3,3,8,0.1)',
        zlevel: 0,
      },
    }
  },
  created () {
    this.$nextTick(() => {
      this.echartsRegisterCustomShape()
      //  质量检查问题统计
      this.qutyCheckChart.instance1 = this.$echarts.init(document.getElementById('qutyCheckColumnChart'))
      this.qutyCheckChart.instance2 = this.$echarts.init(document.getElementById('qutyCheckPieChart'))

      //  安全检查问题统计
      this.safetyCheckChart.instance1 = this.$echarts.init(document.getElementById('safetyCheckColumnChart'))
      this.safetyCheckChart.instance2 = this.$echarts.init(document.getElementById('safetyCheckPieChart'))
    })
  },
  mounted() {
    this.$nextTick(() => {
      
    })
  },
  methods: {
    // 质量问题统计分析
    qutyCheckChartHandle () {
      if (this.$hasPermission('home:dashboard:qutyCheck')) {
        let chartData = {
          months: [],
          vals: [],
        }
        this.qutyCheckChart.instance1.showLoading(this.chartLoadingText);
        this.$http.get(`/opm/home/qutyCheckChart`,{
          params: {
            prjId: this.qutyCheckChart.prjId,
            year: this.qutyCheckChart.year,
          }
        }).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message({
              message: res.msg,
              type: 'error',
              duration: 3000,
              onClose: () => {
                // do nothing
              }
            })
          }
          chartData = res.data
          this.qutyCheckColumnChartOption(chartData)
          this.qutyCheckPieChartOption(chartData)
          this.qutyCheckChart.instance1.hideLoading()
        }).catch(() => {
          // do nothing
        })
      }
    },
    // 质量问题统计-柱形图
    qutyCheckColumnChartOption (chartData) {
      var option = {
        grid: {// 控制上下左右边距
          x: 20,
          y: 40,
          x2: 20,
          y2: 10,
          containLabel: true
        },
        legend: {
          // data: ['', '']
        },
        xAxis: {
          data: chartData.months,
          axisLabel: {
            formatter: function(value) {
              if (value.startsWith('0')) {
                value = value.substring(1)
              }
              return value + '月'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}'
          }
        },
        series: [
          {
            name: '问题数量',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = 0 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3B80E2'
              }, {
                offset: 1,
                color: '#49BEE5'
              }
              ])
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: color
                  }
                },
                  {
                    type: 'CubeRight',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    },
                  }]
              }
            },
            data: chartData.vals
          },
          {
            type: 'bar',
            label: {
              normal: {
                show: true,
                position: 'top',
                fontSize: 16,
                // color: '#fff',
                offset: [4, -10]
              }
            },
            itemStyle: {
              color: 'transparent'
            },
            data: chartData.vals,
            z: 99
          },
          // {
          //   name: '问题数量',
          //   type: 'bar',
          //   barMaxWidth: '30',
          //   data: chartData.vals,
          //   itemStyle: {
          //     normal: {
          //       // color: '#9933FF',
          //       color: this.getLinears()[0],
					// 	    borderRadius:[20, 20, 0, 0],
          //       label: {
          //         show: true,
          //         position: 'top',
          //       }
          //     }
          //   }
          // },
        ]
      }
      this.qutyCheckChart.instance1.clear()
      this.qutyCheckChart.instance1.setOption(option)
    },
    // 质量问题统计-饼形图
    qutyCheckPieChartOption (chartData) {
      var option = {
        grid: {// 控制上下左右边距
          x: 20,
          y: 40,
          x2: 20,
          y2: 10,
          containLabel: true
        },
        legend: {
          // data: ['', '']
        },
        series: [
          {
            name: "各标段占比",
            type: "pie",
            radius: [60, 40],
            data: chartData.pieChartData,
            itemStyle: {
              normal: {
                // color: '#33CC66',
                color: this.getLinears()[1],
              }
            }
          }
        ]
      }
      this.qutyCheckChart.instance2.clear()
      this.qutyCheckChart.instance2.setOption(option)
    },
    // 安全问题统计分析
    safetyCheckChartHandle () {
      if (this.$hasPermission('home:dashboard:safetyCheck')) {
        let chartData = {
          months: [],
          vals: [],
        }
        this.safetyCheckChart.instance1.showLoading(this.chartLoadingText)
        this.safetyCheckChart.instance2.showLoading(this.chartLoadingText)
        this.$http.get(`/opm/home/safetyCheckChart`,{
          params: {
            prjId: this.safetyCheckChart.prjId,
            year: this.safetyCheckChart.year,
          }
        }).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message({
              message: res.msg,
              type: 'error',
              duration: 3000,
              onClose: () => {
                // do nothing
              }
            })
          }
          chartData = res.data
          this.safetyCheckColumnChartOption(chartData)
          this.safetyCheckPieChartOption(chartData)
          this.safetyCheckChart.instance1.hideLoading()
          this.safetyCheckChart.instance2.hideLoading()
        }).catch(() => {
          // do nothing
        })
      }
    },
    // 安全问题统计-柱形图
    safetyCheckColumnChartOption (chartData) {         
      var option = {
        grid: {// 控制上下左右边距
          x: 20,
          y: 40,
          x2: 20,
          y2: 10,
          containLabel: true
        },
        legend: {
          // data: ['', '']
        },
        xAxis: {
          data: chartData.months,
          axisLabel: {
            formatter: function(value) {
              if (value.startsWith('0')) {
                value = value.substring(1)
              }
              return value + '月'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}'
          }
        },
        series: [
          {
            name: '问题数量',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = 0 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3B80E2'
              }, {
                offset: 1,
                color: '#49BEE5'
              }
              ])
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: color
                  }
                },
                  {
                    type: 'CubeRight',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    },
                  }]
              }
            },
            data: chartData.vals
          },
          {
            type: 'bar',
            label: {
              normal: {
                show: true,
                position: 'top',
                fontSize: 16,
                // color: '#fff',
                offset: [4, -10]
              }
            },
            itemStyle: {
              color: 'transparent'
            },
            data: chartData.vals,
            z: 99
          },
          // {
          //   name: '问题数量',
          //   type: 'bar',
          //   barMaxWidth: '30',
          //   data: chartData.vals,
          //   itemStyle: {
          //     normal: {
          //       // color: '#9933FF',
          //       color: this.getLinears()[5],
					// 	    borderRadius:[20, 20, 0, 0],
          //       label: {
          //         show: true,
          //         position: 'top',
          //       }
          //     }
          //   }
          // },
        ]
      }
      this.safetyCheckChart.instance1.clear()
      this.safetyCheckChart.instance1.setOption(option)
    },
    // 安全问题统计-饼形图
    safetyCheckPieChartOption (chartData) {         
      var option = {
        grid: {// 控制上下左右边距
          x: 20,
          y: 40,
          x2: 20,
          y2: 10,
          containLabel: true
        },
        legend: {
          // data: ['', '']
        },
        series: [
          {
            name: "各标段占比",
            type: "pie",
            radius: [60, 40],
            data: chartData.pieChartData,
            itemStyle: {
              normal: {
                // color: '#33CC66',
                color: this.getLinears()[7],
              }
            }
          }
        ]
      }
      this.safetyCheckChart.instance2.clear()
      this.safetyCheckChart.instance2.setOption(option)
    },
    // 根据当前年份动态生成最近五年的年份选项
    makeYearOptions() {
      let year = new Date().getFullYear()
      let arr = []
      for (let i = 0; i < 4; i++) {
        arr.push(year - i)
      }
      arr.sort()
      return arr;
    },
    // 预设十个渐变色，统计图可直接调用该方法获取颜色
    getLinears() {
      let linears = []

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#02bcff'
        }, {
          offset: 1, color: '#0081ff' // 海蓝
        }],
        global: false
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#00FF00'
        }, {
          offset: 1, color: '#00BB00' // 亮绿
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFB6C1'
        }, {
          offset: 1, color: '#FF69B4' // 桃粉
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFCC99'
        }, {
          offset: 1, color: '#fbbd08' // 明黄
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFCCFF'
        }, {
          offset: 1, color: '#e54d42' // 嫣红
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#00CCFF'
        }, {
          offset: 1, color: '#00CCFF' // 亮蓝
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#99FFFF'
        }, {
          offset: 1, color: '#00FFFF' // 橄榄
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFDDAA'
        }, {
          offset: 1, color: '#EE7700' // 棕褐
        }],
      })
      
      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFCCFF'
        }, {
          offset: 1, color: '#FF00FF' // 粉色
        }],
      })

      return linears
    },
    // echarts注册自定义图形
    echartsRegisterCustomShape() {
      const CubeLeft = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function(ctx, shape) {
          const xAxisPoint = shape.xAxisPoint
          const c0 = [shape.x, shape.y]
          const c1 = [shape.x - 9, shape.y - 9]
          const c2 = [xAxisPoint[0] - 9, xAxisPoint[1] - 9]
          const c3 = [xAxisPoint[0], xAxisPoint[1]]
          ctx.moveTo(c0[0], c0[1]).lineTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1])
              .closePath()
        }
      })
      const CubeRight = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function(ctx, shape) {
          const xAxisPoint = shape.xAxisPoint
          const c1 = [shape.x, shape.y]
          const c2 = [xAxisPoint[0], xAxisPoint[1]]
          const c3 = [xAxisPoint[0] + 18, xAxisPoint[1] - 9]
          const c4 = [shape.x + 18, shape.y - 9]
          ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1])
              .closePath()
        }
      })
      const CubeTop = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function(ctx, shape) {
          if (shape.y - shape.xAxisPoint[1] == 0) {
            return
          }
          const c1 = [shape.x, shape.y]
          const c2 = [shape.x + 18, shape.y - 9]
          const c3 = [shape.x + 9, shape.y - 18]
          const c4 = [shape.x - 9, shape.y - 9]
          ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1])
              .closePath()
        }
      })
      this.$echarts.graphic.registerShape('CubeLeft', CubeLeft)
      this.$echarts.graphic.registerShape('CubeRight', CubeRight)
      this.$echarts.graphic.registerShape('CubeTop', CubeTop)
    }
  }
}
</script>
<style scoped>
  ::v-deep .el-card {
    cursor: pointer;
  }

  ::v-deep .el-card .el-card__header {
    padding: 0;
    height: 30px;
    color: white;
  }

  ::v-deep .el-card__body {
    padding: 1px !important;
  }

  ::v-deep .header-default {
    background: #1cbbb4;
    padding: 5px;
  }

  ::v-deep .header-blue {
    background: #409EFF;
    padding: 5px;
  }

  ::v-deep .header-green {
    background: #67C23A;
    padding: 5px;
  }

  ::v-deep .header-brown {
    background: #E6A23C;
    padding: 5px;
  }

  ::v-deep .header-red {
    background: #F56C6C;
    padding: 5px;
  }

  ::v-deep .scrollbar-card {
    height: 200px;
  }

  ::v-deep .span-danger {
    font-weight: bold;
    font-size: 16px;
    color: red;
  }

  ::v-deep .span-warn {
    font-weight: bold;
    font-size: 16px;
    color: #E6A23C;
  }

  ul {
    padding: 0;
    margin: 0;
    text-align: left;
    list-style-type: none;
  }

  li {
    list-style-type: none;
    padding-top: 5px;
  }

  .grid-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100px;
  }

  .grid-con-icon-bg-blue {
    background: #2d8cf0;
  }

  .grid-con-icon-bg-green {
    background: #64d572;
  }

  .grid-con-icon-bg-orange {
    background: #f25e43;
  }

  .grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
  }

  .grid-cont-right {
    padding-left: 5px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    font-size: 16px;
    color: #999;
  }
  .myMapAngle {
    display: inline-block;
    width: 0px;
    height: 0px;
    position: absolute;
    bottom: -60px;
    border: 14px solid;
    left: 15px;
    bottom: -25px;
    opacity: 0.8;
    border-color: #333333 transparent transparent transparent;
  }
</style>