<template>
  <el-dialog width="60%" :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="作业活动" prop="title">
            <el-input v-model="dataForm.title" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="风险源级别" prop="level">
            <el-input v-model="dataForm.levelName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="可导致的事故" prop="accidents">
            <el-input v-model="dataForm.accidents" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input v-model="dataForm.sortNo" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="危害因素" prop="hagard">
            <el-input type="textarea" v-model="dataForm.hagard" :autosize="{ minRows: 3 }" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="控制措施" prop="measure">
            <el-input type="textarea" v-model="dataForm.measure" :autosize="{ minRows: 3 }" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        title: '',
        accidents: '',
        hagard: '',
        measure: '',
        level: '',
        sortNo: '',
        levelName:''
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/opm/riskSource/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
  }
}
</script>
